import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { getBoxSpacingForProperty } from '@tokens/spacing'
import { BlogH1Sizes, H3 } from '@styles/Typography'
import Image from '@elements/Image'
import Link from '@elements/Link'
import CallToActionLinkText from '@elements/CallToActionLinkText'
import { motion } from 'framer-motion'
import { TeaserBorders, TeaserBackgroundColors } from '@mixins/MixinsTeaser'
import Heading from '@elements/Heading'
import Teaser from '@components/Teasers'
import CallToActionImage from './CallToActionImage'

export const CallToAction = styled(motion.div)`
  transform-origin: '50% 50%';
  width: 100%;
  height: 100%;
  padding-bottom: ${spacing(7)};
  padding-top: ${spacing(3)};

  > a {
    ${TeaserBorders}
    position: relative;
    height: 100%;
    width: 100%;
    display: block;

    ${styledMap('imageType', {
      illustration: css`
        ${TeaserBackgroundColors}
      `,
      default: '',
    })}

    &::before {
      padding-bottom: 75%;
      content: '';
      display: block;
      width: 100%;
    }
  }

  .heading,
  .footer {
    ${getBoxSpacingForProperty('x', {
      property: 'left',
      multiplier: 0.5,
    })};
    position: absolute;
    z-index: ${tokens.z('content')};
    width: 100%;

    ${media.greaterThan('m')`
      left: ${spacing(6)};
    `}
  }

  .heading {
    top: 0;
    margin: -0.25em 0 0;
    width: auto;
  }

  .footer {
    top: 100%;
    margin-top: ${spacing(-1)};
    width: auto;

    .link {
      justify-content: flex-start;
      width: 18rem;
      max-width: calc(100% - ${spacing(2)});
    }
  }
`

export default ({
  heading,
  image,
  link,
  backgroundColor,
  imageType,
  className,
}) => {
  if (imageType === 'none') {
    return (
      <Teaser
        link={link}
        color={backgroundColor}
        title={heading}
        type="default"
        className="teaser"
      />
    )
  }

  const [isHovered, setIsHovered] = useState(false)

  return (
    <CallToAction
      className={className}
      color={backgroundColor}
      to={link.url}
      imageType={imageType}
      whileHover="hovered"
      onHoverStart={() => {
        if (!isHovered) {
          setIsHovered(true)
        }
      }}
      onHoverEnd={() => {
        if (isHovered) {
          setIsHovered(false)
        }
      }}
    >
      <Link link={link} linkStyle="none">
        {heading && (
          <Heading heading={heading} className="heading" headingSize="large" />
        )}
        {image && (
          <CallToActionImage
            image={image}
            imageType={imageType}
            isHovered={isHovered}
            color={backgroundColor}
          />
        )}
        <CallToActionLinkText
          className="footer"
          linkText={link.title}
          isHovered={isHovered}
        />
      </Link>
    </CallToAction>
  )
}
