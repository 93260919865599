import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import Image from '@elements/Image'
import { motion } from 'framer-motion'
import { TeaserBorders, TeaserBackgroundColors } from '@mixins/MixinsTeaser'

export const CallToActionImage = styled(motion.div)`
  ${TeaserBorders}
  overflow: hidden;

  .image-hover::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${tokens.z('aboveContent')};
    display: block;
    content: '';
    background-color: ${tokens.color('black', '02')};
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &,
  .image-hover,
  .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    .image-hover::before {
      opacity: 1;
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  .image {
    ${TeaserBorders}
    padding: 0;
    background: none;

    ${styledMap('imageType', {
      illustration: css`
        ${TeaserBackgroundColors}
        bottom: ${spacing(0)};
        left: ${spacing(0)};
        right: ${spacing(0)};
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        z-index: ${tokens.z('base')};
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: center;



        img {
          mix-blend-mode: multiply;
        }

        &.image--landscape {
          width: 100%;
        }

        &.image--portrait {
          height: calc(100% - ${spacing(4)});
          bottom: ${spacing(2)};

          .gatsby-image-wrapper {
            height: 100%;
          }
        }
      `,
      default: css`
        &,
        .gatsby-image-wrapper {
          top: 0;
          left: 0;
          z-index: ${tokens.z('base')};
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      `,
    })};
  }
`

export default ({ image, isHovered, className, imageType, color }) => {
  return (
    <CallToActionImage
      className={className}
      imageType={imageType}
      color={color}
    >
      <motion.div
        className="image-hover"
        initial="init"
        animate={isHovered ? 'hovered' : 'init'}
        variants={{
          hovered: {
            scale: 1.05,
            transition: {
              type: 'tween',
              duration: 0.3,
              damping: 0,
              ease: 'easeOut',
            },
          },
          init: {
            scale: 1,
          },
        }}
      >
        <Image className="image" source={image} objectFit="cover" />
      </motion.div>
    </CallToActionImage>
  )
}
