import React, { useState, useEffect, useContext } from 'react'
import gql from 'graphql-tag'
import axios from 'axios'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { useSiteSource } from '@hooks/useSiteSource'
import { ComponentInnerBase } from '@mixins/MixinsComponent'
import Address from '@elements/Address'
import Image from '@elements/Image'
import FormatIconBanner from '@elements/FormatIconBanner'
import { BlogH1Sizes, LargeTypeSizes, H3 } from '@styles/Typography'
import { PageContentRTE } from '@mixins/MixinsPage'
import { ListBullets } from '@mixins/MixinsList'
import { TextUnderLine } from '@mixins/MixinsPage'
import { ThemeContext } from '@layout/Layout'
import Link from '@elements/Link'

const GROWLER_QUERY = gql`
  query GROWLER_QUERY {
    growlerStation {
      data {
        growlerCore {
          beers {
            ... on Beer {
              uri
              title
            }
          }
          fieldGroupName
          heading
        }
        growlerFlight {
          content
          heading
        }
        growlerLimited {
          heading
          beers {
            ... on Beer {
              uri
              title
            }
          }
        }
      }
    }
  }
`

export const ContactGrowlerStation = styled.div`
  ${PageContentRTE}
  ${box({
    spacing: 'y',
    type: 'margin',
  })};
  position: relative;
  width: 100%;

  .wrap {
    ${box({
      spacing: 'x',
    })};
  }

  .inner {
    ${ComponentInnerBase}
  }

  .heading {
    ${BlogH1Sizes}
    position: relative;
    z-index: ${tokens.z('aboveContent')};
    margin-bottom: -0.5em;
    max-width: ${tokens.get('maxWidth.tiny')};
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('m')`
      max-width: ${tokens.get('maxWidth.narrow')};
    `}
  }

  .content-inner {
    position: relative;
    z-index: ${tokens.z('content')};
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .image,
  .content {
    ${media.greaterThan('s')`
      flex: 0 1 calc(50% - ${spacing(3)});
    `}
  }

  .image {
    display: none;

    ${media.greaterThan('s')`
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(50% - ${spacing(3)});
    `}
  }

  .content {
    ${box({
      spacing: 'top',
      multiplier: 0.5,
      type: 'margin',
    })};
    margin-right: auto;
    margin-left: auto;
    max-width: ${tokens.get('maxWidth.tiny')};

    ${media.greaterThan('s')`
      margin-right: 0;
    `}

    &--taps {
      margin-top: 0;

      ${media.lessThan('m')`
        flex: 0 1 ${tokens.get('maxWidth.tiny')};
        margin-right: auto;
      `}
    }
  }

  .summary {
    ${LargeTypeSizes}
  }

  .contact-info {
    margin-top: ${spacing(2)};

    ${media.greaterThan('s')`
      margin-top: ${spacing(4)};
    `}
  }

  h3 {
    margin-top: ${spacing(4)};
    margin-bottom: ${spacing(2)};
  }

  .tap {
    &:first-child h3 {
      margin-top: 0;
    }

    + .tap {
      margin-top: ${spacing(4)};
    }
  }

  ul {
    margin-left: 0;
  }

  li {
    ${ListBullets}
    margin-top: ${spacing(1)};

    &:first-child {
      margin-top: 0;
    }
  }

  .growler-list-item {
    &:before {
      display: none;
    }

    ${media.lessThan('s')`
      margin-left: 0;
    `}

    a {
      line-height: ${tokens.get('type.lineHeight.list')};
      ${TextUnderLine}
    }
  }

  .banner {
    ${box({
      spacing: 'y',
      multiplier: 0.5,
      type: 'margin',
    })};
  }
`

export default ({
  growlerStation: { heading, content, taps: initialTaps, image },
  brewery: { address, email, hoursOfOperation: hours },
}) => {
  const siteSourceUrl = useSiteSource()
  const { theme } = useContext(ThemeContext)
  const [taps, setTaps] = useState(initialTaps)
  const breweryAddress = {
    ...address,
    address_title: '',
  }

  const getGrowler = async () => {
    return axios({
      url: siteSourceUrl,
      method: 'post',
      data: {
        query: GROWLER_QUERY.loc.source.body,
      },
    })
      .then(
        ({
          data: {
            data: {
              growlerStation: { data },
            },
          },
        }) => {
          return [data.growlerLimited, data.growlerCore, data.growlerFlight]
        }
      )
      .catch((error) => console.dir(error.message))
  }

  useEffect(() => {
    getGrowler().then((dynamicTaps) => {
      setTaps(dynamicTaps)
    })
  }, [])

  return (
    <ContactGrowlerStation size="wide" theme={theme}>
      <div className="wrap">
        <div className="inner">
          {heading && (
            <h2
              className="heading"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          )}
          <div className="content-inner">
            {image && <Image source={image} />}
            <div className="content">
              {content && (
                <div
                  className="summary"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
              <ul className="contact-info">
                {address && (
                  <li>
                    <Address address={breweryAddress} />
                  </li>
                )}
                {email && (
                  <li>
                    <a href={`mailto:${email}`}>{email}</a>
                  </li>
                )}
                {hours &&
                  hours.map(({ line }, index) => {
                    return <li key={index}>{line}</li>
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FormatIconBanner className="banner" />
      <div className="wrap">
        <div className="inner">
          <div className="content-inner">
            <div className="content content--taps">
              {taps &&
                taps.map(
                  ({ heading: title, content: summary, beers }, index) => {
                    if (!title && !summary && !beers) {
                      return null
                    }
                    return (
                      <div key={`${title}-${index}`} className="tap">
                        <H3 dangerouslySetInnerHTML={{ __html: title }} />
                        {summary && (
                          <div dangerouslySetInnerHTML={{ __html: summary }} />
                        )}
                        {beers && (
                          <ul>
                            {beers.map(({ title, uri }, i) => {
                              return (
                                <li
                                  className="growler-list-item"
                                  key={`${title}-${i}`}
                                >
                                  <Link
                                    link={{ type: 'url', url: uri, title }}
                                    linkStyle="none"
                                  />
                                </li>
                              )
                            })}
                          </ul>
                        )}
                      </div>
                    )
                  }
                )}
            </div>
          </div>
        </div>
      </div>
    </ContactGrowlerStation>
  )
}
