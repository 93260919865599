import React, { useRef, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'
import { motion } from 'framer-motion'

const GalleryGrid1 = css`
  width: 100%;
`

const GalleryGrid2 = css`
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-rows: 4rem auto;

  ${media.greaterThan('s')`
    grid-template-rows: 8rem auto;
  `}

  > div {
    position: relative;
    z-index: ${tokens.z('content')};
    transition: opacity 100ms linear;

    &:first-child {
      grid-column: 1 / 6;

      ${styledMap('imageAlign', {
        left: css`
          grid-row: 1 / 2;
          z-index: ${tokens.z('aboveContent')};
        `,
        right: css`
          grid-row: 2 / 3;
        `,
      })}
    }

    &:last-child {
      grid-column: 5 / 10;

      ${styledMap('imageAlign', {
        left: css`
          grid-row: 2 / 3;
        `,
        right: css`
          grid-row: 1 / 2;
          z-index: ${tokens.z('aboveContent')};
        `,
      })}
    }

    &:hover {
      z-index: ${tokens.z('aboveContent') + 1};
      transition: none;
    }
  }
`

const GalleryGrid3 = css`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing(2)};

  ${media.greaterThan('s')`
    grid-gap: ${spacing(4)};
  `}

  ${media.greaterThan('l')`
    grid-gap: ${spacing(6)};
  `}

  .gatsby-image-wrapper {
    height: 100%;
  }

  .gallery-image {
    &:first-child {
      ${styledMap('featuredStyle', {
        landscape: css`
          grid-column: 1 / 3;
        `,
        portrait: css`
          grid-row: 1 / 3;

          ${styledMap('imageAlign', {
            right: css`
              grid-column: 2 / -1;
            `,
            default: '',
          })};
        `,
      })}
    }

    &:nth-child(2) {
      ${styledMap('featuredStyle', {
        landscape: css``,
        portrait: css`
          grid-row: 1 / 2;
        `,
      })}

      > div,
      .image {
        height: 100%;
      }
    }

    &:nth-child(3) {
      ${styledMap('featuredStyle', {
        portrait: css`
          max-width: 19rem;

          ${styledMap('imageAlign', {
            right: css`
              margin-left: auto;
            `,
            default: '',
          })};

          ${media.lessThan('s')`
            > div,
            .image {
              height: 100%;
            }
          `}
        `,
        default: css`
          .gatsby-image-wrapper {
            height: auto;
          }
        `,
      })}
    }
  }
`

export const GalleryImages = styled.div`
  position: relative;
  display: grid;

  ${styledMap('imageCount', {
    3: GalleryGrid3,
    2: GalleryGrid2,
    1: GalleryGrid1,
  })};

  .gallery-image {
    display: block;
    width: 100%;
  }
`

export default ({
  className = '',
  fullWidth,
  featuredStyle = 'landscape',
  imageAlign,
  imageCount,
  children,
}) => {
  const ref = children.length === 2 ? useRef() : null
  const targetIndex = imageAlign === 'right' ? 1 : 0
  const handleHover = (index, event) => {
    const siblingHovered = [...ref.current.childNodes].some((childNode, i) => {
      return (
        ref.current.childNodes[index] !== childNode &&
        childNode.matches(':hover')
      )
    })

    // Hover on
    if (event === 'start') {
      ref.current.childNodes.forEach((element, i) => {
        element.style.opacity = i === index ? 1 : 0.8
      })
    }

    // Hover off of both.
    if (!siblingHovered && event === 'end' && targetIndex !== index) {
      ref.current.childNodes.forEach((element, i) => {
        element.style.opacity = i === targetIndex ? 1 : 0.8
      })
    }
  }

  return (
    <GalleryImages
      ref={ref}
      className={className}
      imageCount={imageCount}
      fullWidth={fullWidth}
      featuredStyle={featuredStyle}
      imageAlign={imageAlign}
    >
      {children.length === 2
        ? children.map((child, index) => {
            return (
              <motion.div
                key={index}
                style={{
                  opacity: index === targetIndex ? 1 : 0.8,
                }}
                transition={{ duration: 0.3 }}
                onHoverStart={() => {
                  handleHover(index, 'start')
                }}
                onHoverEnd={() => {
                  handleHover(index, 'end')
                }}
              >
                {child}
              </motion.div>
            )
          })
        : children}
    </GalleryImages>
  )
}
