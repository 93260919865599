import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H3 } from '@styles/Typography'

export const Address = styled.div``

export default ({ address: { addressTitle, ...address }, className }) => {
  const fullAddress = Object.values(address).join(', ')
  const url = `https://www.google.com/maps/search/${encodeURI(fullAddress)}`
  return (
    <Address className={className}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {fullAddress && fullAddress}
      </a>
    </Address>
  )
}
