import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import Social from '@blocks/Social'
import Address from '@elements/Address'
import MediaItem from '@blocks/MediaItem'
import { H3 } from '@styles/Typography'
import TextContent from '@elements/TextContent'
import { ComponentBase } from '@mixins/MixinsComponent'

export const ContactDetails = styled.div`
  ${ComponentBase}
  position: relative;

  .inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .illustration {
    display: none;
    max-width: 9rem;
    width: 80%;

    ${media.greaterThan('s')`
      display: block;
      margin-top: ${spacing(-1)};
    `}

    ${media.greaterThan('m')`
      width: 9rem;
    `}
  }

  .address {
    a {
      line-height: ${tokens.get('type.lineHeight.list')};
    }
  }

  .contact-details {
    margin-left: auto;
    margin-right: auto;

    ${media.lessThan('s')`
      width: 100%;
      max-width: ${tokens.get('maxWidth.tiny')};
    `}

    ${media.greaterThan('s')`
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
      flex-basis: ${tokens.get('maxWidth.narrow')};
      max-width: ${tokens.get('maxWidth.narrow')};
    `}
  }

  .contact-detail {
    margin-top: ${spacing(3)};

    &:first-child {
      margin-top: 0;
    }

    ${media.greaterThan('s')`
      flex: 0 1 calc(50% - ${spacing(2)});
      width: calc(50% - ${spacing(2)});
      margin: 0;
    `}
  }

  .social {
    flex: 0 1 auto;
    margin-top: ${spacing(4)};
  }

  h3 {
    margin-bottom: ${spacing(2)};
  }
`

export default ({
  contact: { illustration },
  headOffice,
  brewery,
  isFlex,
  size,
}) => {
  return (
    <ContactDetails isFlex={isFlex} size={size}>
      <div className="inner">
        <div className="contact-details">
          {[headOffice, brewery].map(
            ({ address, phoneNumber, hoursOfOperation: hours }, index) => {
              return (
                <TextContent key={index} className="address contact-detail">
                  {address?.addressTitle && <H3>{address.addressTitle}</H3>}
                  <Address address={address} />
                  {phoneNumber && phoneNumber}
                  {index === 0 &&
                    hours &&
                    hours.map(({ line }, index) => {
                      return <div key={index}>{line}</div>
                    })}
                </TextContent>
              )
            }
          )}

          <div className="social contact-detail">
            <H3>Social</H3>
            <Social />
          </div>
          {illustration && (
            <div className="contact-detail">
              <MediaItem
                className="illustration"
                media={{
                  mediaType: 'illustration',
                  image: illustration,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </ContactDetails>
  )
}
