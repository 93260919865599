import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@layout/Container'
import { formatTemplateData } from '@utils/templates'
import ComponentContainer from '@layout/ComponentContainer'
import ContactDetails from '@components/Contact/ContactDetails'
import ContactGrowlerStation from '@components/Contact/ContactGrowlerStation'
import ContactList from '@components/Contact/ContactList'
import CallToActionRow from '@components/CallToAction/CallToActionRow'
import ContactBreweryTours from '@components/Contact/ContactBreweryTours'
import ContactKegRentals from '@components/Contact/ContactKegRentals'
import ContactNewsletter from '@components/Contact/ContactNewsletter'

const ContactPage = ({ data }) => {
  const {
    wpPage: { templateContact: pageData, title, seo, uri },
    wp: {
      growlerStation: {
        data: { growlerCore, growlerFlight, growlerLimited },
      },
      companyDetails: {
        contact: { headOffice, brewery },
      },
    },
  } = data
  seo.path = uri
  const template = {
    ...pageData,
    title,
    activeSlug: pageData.slug,
    designations: 'isContactPage',
    heroType: 'small',
    seo,
  }
  const {
    contact,
    contactGroups,
    breweryTours,
    kegRentals,
    newsletter,
    callToActions,
    growlerStation,
  } = pageData

  growlerStation.taps = [growlerLimited, growlerCore, growlerFlight]

  return (
    <Container template={formatTemplateData(template)}>
      <ContactDetails
        headOffice={headOffice}
        brewery={brewery}
        contact={contact}
        size="wide"
        isFlex
      />

      <ContactGrowlerStation
        brewery={brewery}
        growlerStation={growlerStation}
      />

      <ComponentContainer size="wide">
        <ContactKegRentals kegRentals={kegRentals} />
      </ComponentContainer>
      <ComponentContainer size="wide">
        <ContactBreweryTours breweryTours={breweryTours} />
      </ComponentContainer>
      <ComponentContainer size="wide">
        <CallToActionRow callToActions={callToActions} />
      </ComponentContainer>
      <ComponentContainer size="wide">
        <ContactList contactGroups={contactGroups} />
      </ComponentContainer>
      <ComponentContainer size="wide">
        <ContactNewsletter newsletter={newsletter} />
      </ComponentContainer>
    </Container>
  )
}

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      uri
      templateContact {
        breweryTours {
          heading
          content
          gallery {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            mediaDetails {
              width
              height
            }
          }
        }
        contact {
          illustration {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            mediaDetails {
              width
              height
            }
          }
        }
        contactGroups {
          contacts {
            email
            name
          }
          title
        }
        kegRentals {
          heading
          content
          illustration {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            mediaDetails {
              width
              height
            }
          }
        }
        growlerStation {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            mediaDetails {
              width
              height
            }
          }
        }
        callToActions {
          heading
          backgroundColor
          imageType
          link {
            target
            title
            url
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            mediaDetails {
              width
              height
            }
          }
        }
        newsletter {
          heading
          content
          # form {
          #   wordpress_id
          #   title
          #   description
          #   wordpress_fields {
          #     type
          #     wordpress_id
          #     label
          #     isRequired
          #     errorMessage
          #     description
          #     placeholder
          #     cssClass
          #     defaultValue
          #   }
          #   button {
          #     text
          #   }
          # }
          illustration {
            localFile {
              childImageSharp {
                fluid(maxWidth: 460) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            mediaDetails {
              width
              height
            }
          }
        }
      }
      seo {
        title
        twitterTitle
        twitterDescription
        twitterImage {
          description
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphDescription
        metaRobotsNoindex
        metaRobotsNofollow
        metaDesc
      }
    }
    wp {
      growlerStation {
        data {
          growlerCore {
            heading
            beers {
              ... on WpBeer {
                id
                uri
                title
              }
            }
          }
          growlerFlight {
            heading
            content
          }
          growlerLimited {
            heading
            beers {
              ... on WpBeer {
                id
                title
                uri
              }
            }
          }
        }
      }
      companyDetails {
        contact {
          headOffice {
            address {
              addressTitle
              streetAddress
              city
              province
            }
            hoursOfOperation {
              line
            }
            phoneNumber
          }
          brewery {
            address {
              addressTitle
              streetAddress
              city
              province
            }
            email
            hoursOfOperation {
              line
            }
          }
        }
      }
    }
  }
`

export default ContactPage
