import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import MediaItem from '@blocks/MediaItem'
import Newsletter from '@elements/Newsletter'
import Heading from '@elements/Heading'
import TextContent from '@elements/TextContent'

export const ContactNewsletter = styled.div`
  > .inner {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${spacing(8)};
    grid-row-gap: ${spacing(8)};
  }

  .illustration {
    width: 12.75rem;

    ${media.lessThan('m')`
      display: none;
    `}
  }

  .form {
    grid-column: 1 / -1;

    ${media.greaterThan('m')`
      grid-column: 2 / -1;
    `}
  }

  .summary {
    margin-bottom: ${spacing(3)};
    margin-left: 0;

    ${media.greaterThan('m')`
      margin-bottom: ${spacing(6)};
    `}
  }
`

export default ({ newsletter: { illustration, heading, content } }) => {
  return (
    <ContactNewsletter>
      <div className="inner">
        {illustration && (
          <MediaItem
            className="illustration"
            media={{
              mediaType: 'illustration',
              image: illustration,
            }}
          />
        )}
        <Newsletter className="form">
          <Heading heading={heading} />
          <TextContent content={content} textSize="large" size="tiny" isIntro />
        </Newsletter>
      </div>
    </ContactNewsletter>
  )
}
