import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { BlogH1Sizes, LargeTypeSizes } from '@styles/Typography'
import Heading from '@elements/Heading'
import TextContent from '@elements/TextContent'
import MediaItem from '@blocks/MediaItem'

export const ContactKegRentals = styled.div`
  .inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
  }

  .heading {
    ${BlogH1Sizes}
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('m')`
      margin-bottom: ${spacing(5)};
      text-align: center;
    `}

    ${media.lessThan('m')`
      max-width: ${tokens.get('maxWidth.tiny')};
    `}
  }

  .illustration {
    flex: 0 1 10rem;
    width: 10rem;
    margin-right: 5rem;
    margin-left: auto;

    ${media.lessThan('m')`
      display: none;
    `}
  }

  .content {
    ${media.greaterThan('l')`
      margin-left: 0;
    `}

    p:first-child {
      ${LargeTypeSizes}
    }
  }
`

export default ({ kegRentals: { heading, content, illustration } }) => {
  return (
    <ContactKegRentals>
      <Heading heading={heading} />
      <div className="inner">
        {illustration && (
          <MediaItem
            media={{
              image: illustration,
              mediaType: 'illustration',
            }}
            className="illustration"
          />
        )}
        <TextContent className="content" size="tiny" content={content} />
      </div>
    </ContactKegRentals>
  )
}
