import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { BlogH1Sizes, LargeTypeSizes } from '@styles/Typography'
import GalleryImages from '@components/Gallery/GalleryImages'
import ComponentContent from '@blocks/ComponentContent'
import Image from '@elements/Image'

export const ContactBreweryTours = styled.div`
  .heading {
    ${BlogH1Sizes}
    margin-top: -0.45em;
  }

  .summary {
    p:first-child {
      ${LargeTypeSizes}
    }
  }

  .content-container {
    position: relative;
    z-index: ${tokens.z('aboveContent')};
    max-width: ${tokens.get('maxWidth.base')};
    margin-left: 0;
    margin-right: auto;
  }

  .content {
    max-width: ${tokens.get('maxWidth.tiny')};
  }
`

export default ({ breweryTours: { heading, content, gallery } }) => {
  return (
    <ContactBreweryTours>
      <GalleryImages
        imageCount="2"
        featuredOrientation="right"
        imageAlign="left"
      >
        {gallery &&
          gallery.map((image, index) => {
            return (
              <Image key={index} className="gallery-image" source={image} />
            )
          })}
      </GalleryImages>
      <div className="content-container">
        <ComponentContent
          className="content"
          textContent={{
            heading,
            content,
            selectedFields: ['heading', 'content'],
          }}
          size="tiny"
        />
      </div>
    </ContactBreweryTours>
  )
}
