import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H3 } from '@styles/Typography'
import { getBoxSpacingForProperty } from '@tokens/spacing'

export const ContactList = styled.div`
  ${getBoxSpacingForProperty('y', {
    property: 'grid-row-gap',
    multiplier: 0.5,
  })};
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${spacing(3)};

  ${media.greaterThan('s')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.greaterThan('m')`
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${spacing(6)};
  `}

  .contact {
    margin-top: ${spacing(2)};
    font-size: 1rem;
    > * {
      display: block;
    }

    a {
      text-decoration: underline;
      word-break: break-word;

      &:hover {
        color: ${tokens.color('red')};
      }
    }
  }
`

export default ({ contactGroups }) => {
  return (
    <ContactList>
      {contactGroups &&
        contactGroups.map(({ contacts, title }, index) => {
          return (
            <div key={`${title}-${index}`} className="column">
              <H3>{title}</H3>
              {contacts &&
                contacts.map(({ name, email }, i) => {
                  return (
                    <div key={`${name}-${i}`} className="contact">
                      {name && <span className="name">{name}</span>}
                      {email && (
                        <a href={`mailto:${email}`} className="email">
                          {email}
                        </a>
                      )}
                    </div>
                  )
                })}
            </div>
          )
        })}
    </ContactList>
  )
}
