import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H3 } from '@styles/Typography'
import CallToAction from '@components/CallToAction/CallToAction'

const FullWidthCTA = css`
  ${box({
    spacing: 'bottom',
    multiplier: 0.25,
    type: 'margin',
  })};
  flex: 0 1 20rem;
  margin-left: auto;
  margin-right: auto;

  ${media.greaterThan('s')`
    flex-basis: ${tokens.get('maxWidth.tiny')};
  `}

  ${media.greaterThan('m')`
    ${box({
      spacing: 'bottom',
      multiplier: 0.5,
      type: 'margin',
    })};
    flex-basis: ${tokens.get('maxWidth.narrow')};
  `}

  &:last-child {
    margin-bottom: 0;
  }

  > a {
    ${box({
      spacing: 'x',
      multiplier: -0.5,
      type: 'margin',
    })};
    display: flex;
    width: auto;

    ${media.greaterThan('m')`
      margin-left: ${spacing(-6)};
      margin-right: ${spacing(-6)};
    `}

    &::before {
      padding-bottom: 60%;

      ${media.greaterThan('s')`
        padding-bottom: 51.1%;
      `}
    }
  }
`

export const CallToActionRow = styled.div`
  .row {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;

    .call-to-action {
      flex: 0 1 calc(50% - ${spacing(3)});

      ${styledMap('hasFeatured', {
        true: css`
          &:first-child {
            ${FullWidthCTA}
          }
        `,
        default: '',
      })};

      ${media.lessThan('m')`
        ${FullWidthCTA}
      `}
    }

    .teaser {
      width: calc(100% - 2.75rem);
      max-width: 19rem;
      margin-top: ${spacing(4)};

      &:first-child {
        margin-top: 0;
      }

      ${media.greaterThan('s')`
        margin-top: 0;
        max-width: none;
        width: calc(50% - ${spacing(3)});
        flex: 0 1 calc(50% - ${spacing(3)});
      `}
    }
  }
`

export default ({ heading, callToActions, hasFeatured }) => {
  return (
    <CallToActionRow hasFeatured={hasFeatured}>
      {heading && <H3 className="heading">{heading}</H3>}
      <div className="row">
        {callToActions &&
          callToActions.map(
            (
              { heading: ctaHeading, image, link, backgroundColor, imageType },
              index
            ) => {
              return (
                <CallToAction
                  key={`${link.title}-${index}`}
                  className="call-to-action"
                  heading={ctaHeading}
                  image={image}
                  link={link}
                  imageType={imageType}
                  backgroundColor={backgroundColor}
                />
              )
            }
          )}
      </div>
    </CallToActionRow>
  )
}
